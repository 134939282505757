<template>
  <v-container v-if="vueProduto">
    <template v-if="!loading">
      <PageToolbar
        :title="produto.descricao"
        icon="shopping_cart"
        voltar
        dark
      />

      <v-card class="mt-4">
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
              md="2"
              class="pt-8 lightgray"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'border-right: 1px solid rgba(0, 0, 0, 0.12) !important;'
                  : ''
              "
            >
              <div class="d-flex justify-center align-center">
                <v-hover v-slot:default="{ hover }">
                  <v-avatar
                    size="120"
                    style="box-shadow: 0px 0px 0px 3px rgba(32,140,110,1);"
                  >
                    <v-img
                      :src="
                        produto.img
                          ? apiUrl + produto.img
                          : require('@/assets/avatar_placeholder.png')
                      "
                    >
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="d-flex transition-fast-in-fast-out darkblue v-card--reveal white--text"
                          style="height: 34px; "
                        >
                          <v-btn
                            depressed
                            text
                            x-small
                            dark
                            class="mt-1"
                            @click="dialogImages = true"
                          >
                            alterar
                          </v-btn>
                        </div>
                      </v-expand-transition>
                    </v-img>
                  </v-avatar>
                </v-hover>
              </div>
              <v-card outlined class="mt-6">
                <v-tabs v-model="tab" vertical>
                  <v-tab class="caption font-weight-bold justify-start">
                    Cadastro
                  </v-tab>
                  <v-tab
                    class="caption font-weight-bold justify-start"
                    v-if="vueInventario"
                  >
                    Inventario
                  </v-tab>
                </v-tabs>
              </v-card>
            </v-col>

            <v-col cols="12" md="10">
              <v-tabs-items v-model="tab">
                <!-- Cadastro -->
                <v-tab-item>
                  <v-row>
                    <!-- Descrição -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="produto.descricao"
                        label="Descrição"
                        filled
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- Classificações -->
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="produto.produto_classificacao_id"
                        :items="classificacoes"
                        :loading="loadingClass"
                        clearable
                        item-text="descricao"
                        item-value="id"
                        dense
                        filled
                        hide-details
                        label="Classificações"
                      ></v-select>
                    </v-col>

                    <!-- Referencia -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="produto.referencia"
                        label="Referencia"
                        filled
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- Fornecedor -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="produto.fornecedor"
                        label="Fornecedor"
                        filled
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- Costo -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="produto.costo"
                        label="Costo"
                        filled
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- Precio -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="produto.precio"
                        label="Precio"
                        filled
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- Url -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="produto.url"
                        label="Url"
                        filled
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- Estoque Min -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="produto.estoque_min"
                        label="Estoque Min"
                        filled
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- Estoque Max -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="produto.estoque_max"
                        label="Estoque Max"
                        filled
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- Exige Serial -->
                    <v-col cols="12" md="4">
                      <v-card
                        flat
                        style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                        height="56px"
                        class="pa-3 d-flex justify-space-between align-center"
                      >
                        <v-subheader
                          style="height: auto !important"
                          class="ma-0 pa-0 body-1"
                        >
                          Exige Serial
                        </v-subheader>

                        <v-switch
                          class="ma-0 pa-0"
                          hide-details
                          :false-value="false"
                          :true-value="true"
                          v-model="produto.exige_serial"
                        ></v-switch>
                      </v-card>
                    </v-col>

                    <!-- Qtde por Caixa -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="produto.qtde_caixa"
                        label="Quantidade por caixa"
                        filled
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- Unidade de Medida -->
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="produto.und"
                        :items="unidades"
                        clearable
                        dense
                        filled
                        hide-details
                        label="Unidade de Medida"
                      ></v-select>
                    </v-col>

                    <!-- Descrição detalhada -->
                    <v-col cols="12">
                      <v-textarea
                        v-model="produto.descricao_detalhada"
                        label="Descrição detalhada"
                        :rows="3"
                        filled
                        hide-details
                        dense
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-btn
                    v-if="vueEditProduto"
                    @click="updateProduto"
                    fixed
                    right
                    bottom
                    fab
                    dark
                    color="buttons"
                    :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
                  >
                    <v-icon>save</v-icon>
                  </v-btn>
                </v-tab-item>

                <!-- Inventario -->
                <v-tab-item v-if="vueInventario">
                  <v-card outlined>
                    <v-card-title class="subtitle-1 lightgray">
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="vueAddProdutoMovimentacoes"
                        color="buttons"
                        depressed
                        dark
                        @click="modalMovimentacao = true"
                      >
                        Movimentação
                        <v-icon right>add</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text v-if="depositos">
                      <v-data-table
                        :headers="headersDepositos"
                        :items="depositos"
                        :items-per-page="-1"
                        :sort-by="['id']"
                        class="data-tables "
                        :class="{
                          'data-tables__row-click': vueProdutoMovimentacoes,
                        }"
                        @click:row="openDepositoMovimentacoes"
                      >
                        <template v-slot:body.append>
                          <tr>
                            <td>Total:</td>
                            <td></td>
                            <td></td>
                            <td>
                              <b>{{ saldoTotalDepositos }}</b>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
    <Carregando v-else />

    <SelectImageModal
      :dialogImages.sync="dialogImages"
      origem="produtos"
      :origem_id="produtoId"
    >
      <template v-slot:saveSelected>
        <v-spacer></v-spacer>
        <v-fade-transition>
          <v-btn
            v-if="imagemSelecionada"
            depressed
            dark
            small
            color="toolbar"
            @click="updateAvatar(imagemSelecionada.path_original)"
          >
            Salvar
            <v-icon>save</v-icon>
          </v-btn>
        </v-fade-transition>
      </template>
    </SelectImageModal>

    <v-dialog v-model="modalMovimentacoes">
      <v-card>
        <v-card-title class="subtitle-1 lightgray">
          {{ deposito.deposito }} / {{ produto.descricao }}
          <v-spacer></v-spacer>
          <v-btn icon @click="modalMovimentacoes = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-data-table
            :headers="headers"
            :items="movimentacoes"
            :items-per-page="-1"
            :sort-by="['created_at']"
            :sort-desc="true"
            class="data-tables"
          >
            <template v-slot:item.created_at="{ item }">
              <v-icon color="success" class="mr-2" v-if="item.op === 'E'">
                add_circle
              </v-icon>
              <v-icon color="error" class="mr-2" v-if="item.op === 'S'">
                remove_circle
              </v-icon>
              {{ item.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
            </template>
            <template v-slot:item.qtde="{ item }">
              {{ item.qtde }}
            </template>

            <template v-slot:item.custo="{ item }">
              <v-text-field
                v-if="item.editCusto"
                v-model="item.custo"
                placeholder="Valor"
                dense
                hide-details
                @click:append.stop="updateMovimentacao(item)"
                @blur="item.editCusto = false"
                :append-icon="item.editCusto ? 'save' : ''"
              ></v-text-field>
              <div v-else>
                {{ item.custo | guarani }}
                <v-btn small icon @click="item.editCusto = true">
                  <v-icon small>edit</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:item.cliente="{ item }">
              {{ item.cliente }}
              <v-btn
                v-if="item.evento_id"
                :to="{ path: `/eventos/${item.evento_id}` }"
                icon
                x-small
                class="ml-1"
              >
                <v-icon>link</v-icon>
              </v-btn>
            </template>

            <template v-slot:body.append>
              <tr>
                <td>Saldo:</td>
                <td></td>
                <td>
                  <b>{{ saldoTotal }}</b>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalMovimentacao" max-width="400px">
      <v-card>
        <v-card-title class="subtitle-1 lightgray">
          Adicionar Movimentação
          <v-spacer></v-spacer>
          <v-btn icon @click="modalMovimentacao = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- Depositos -->
          <v-select
            v-model="movimentacao.deposito_id"
            :items="selectDepositos"
            class="mt-4"
            clearable
            item-text="descricao"
            item-value="id"
            dense
            filled
            hide-details
            :label="transferencia ? 'Deposito Origem' : 'Deposito'"
          ></v-select>
          <!-- É transferencia -->
          <v-card
            v-if="depositos.length"
            flat
            style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
            height="56px"
            class="pa-3 mt-4 d-flex justify-space-between align-center"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 body-1"
            >
              Transferencia
            </v-subheader>

            <v-switch
              class="ma-0 pa-0"
              hide-details
              :false-value="false"
              :true-value="true"
              v-model="transferencia"
            ></v-switch>
          </v-card>
          <!-- Qtde -->
          <v-text-field
            v-model="movimentacao.qtde"
            label="Quantidade"
            class="mt-4"
            filled
            hide-details
            dense
          ></v-text-field>

          <!-- Deposito Final -->
          <v-select
            v-if="transferencia"
            v-model="movimentacao.deposito_destino_id"
            :items="selectDepositos"
            class="mt-4"
            clearable
            item-text="descricao"
            item-value="id"
            dense
            filled
            hide-details
            label="Deposito Destino"
          ></v-select>
          <!-- Custo -->
          <v-text-field
            v-if="showCusto"
            v-model="movimentacao.custo"
            label="Custo"
            class="mt-4"
            filled
            hide-details
            dense
          ></v-text-field>
          <!-- Operacao -->
          <v-card flat class="mt-4" v-if="!transferencia">
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 body-1"
            >
              Operação
            </v-subheader>
            <v-btn-toggle
              style="width: calc(100% / 2);"
              dense
              v-model="movimentacao.op"
              :class="$vuetify.breakpoint.mdAndDown ? 'btn-toggle-mobile' : ''"
            >
              <v-btn small height="36" block value="E">
                Entrada
              </v-btn>

              <v-btn small height="36" block value="S">
                Saida
              </v-btn>
            </v-btn-toggle>
          </v-card>

          <v-textarea
            filled
            hide-details
            class="mt-4"
            label="Obs"
            :rows="2"
            auto-grow
            v-model="movimentacao.obs"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="toolbar"
            dark
            depressed
            small
            :disabled="disableAddMovimentacao"
            @click="addMovimentacao"
          >
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import {
  getOneProdutos,
  getAllProClass,
  putProdutos,
  getProdutoMovimentacoes,
  putProdutoMovimentacoes,
  postProdutoMovimentacoes,
  fetchDepositos,
} from "@/services/api/produtos.api.js";

import { mapState, mapGetters } from "vuex";

export default {
  name: "Produto",
  props: {
    produtoId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    SelectImageModal: () => import("@/components/SelectImageModal"),
  },
  data() {
    return {
      depositoSelecionado: null,
      modalMovimentacao: false,
      modalMovimentacoes: false,
      transferencia: false,
      disableModal: false,
      produto: {},
      custoMedio: null,
      original: {},
      classificacoes: [],
      depositos: [],
      selectDepositos: [],
      deposito: {},
      loadingClass: true,
      tab: null,
      movimentacoes: [],
      movimentacao: {},
      headers: [
        {
          text: "Data",
          value: "created_at",
        },
        {
          text: "Operacao",
          value: "origem",
        },
        {
          text: "Quantidade",
          value: "qtde",
        },

        {
          text: "Serial",
          value: "serial",
        },
        {
          text: "Tipo",
          value: "tipoevento",
        },
        {
          text: "Origem",
          value: "cliente",
        },
        {
          text: "Obs",
          value: "obs",
        },
      ],
      headersDepositos: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Deposito",
          value: "deposito",
        },
        {
          text: "Cidade",
          value: "cidade",
        },
        {
          text: "Saldo",
          value: "saldo",
        },
      ],
      unidades: [
        {
          text: "Metros",
          value: "mts",
        },
        {
          text: "Unidades",
          value: "und",
        },
      ],
      dialogImages: false,
      apiUrl: null,
      loading: false,
    };
  },
  computed: {
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
    }),
    ...mapState({
      imagemSelecionada: (state) => state.imagemSelecionada,
    }),
    ...mapGetters("Componentes", ["getAccess"]),
    vueProduto() {
      return this.getAccess("vueProduto", "visualizar");
    },
    vueEditProduto() {
      return this.getAccess("vueProduto", "editar");
    },
    vueInventario() {
      return this.getAccess("vueProdutoMovimentacoes", "menu");
    },
    vueProdutoMovimentacoes() {
      return this.getAccess("vueProdutoMovimentacoes", "visualizar");
    },
    vueAddProdutoMovimentacoes() {
      return this.getAccess("vueProdutoMovimentacoes", "adicionar");
    },
    saldoTotal() {
      let saldo = 0;
      this.movimentacoes.forEach((item) => {
        if (item.op === "E") {
          saldo += Number(item.qtde);
        }
        if (item.op === "S") {
          saldo -= Number(item.qtde);
        }
      });
      return saldo;
    },
    saldoTotalDepositos() {
      let saldo = 0;
      this.depositos.forEach((item) => {
        saldo += Number(item.saldo);
      });
      return saldo;
    },
    showCusto() {
      let show = false;
      if (!this.custoMedio) {
        show = true;
      } else {
        if (!this.transferencia && this.movimentacao.op != "S") {
          show = true;
        }
      }

      return show;
    },
    disableAddMovimentacao() {
      if (this.transferencia) {
        if (
          this.movimentacao.qtde &&
          this.movimentacao.deposito_id &&
          this.movimentacao.deposito_destino_id &&
          this.movimentacao.deposito_id != this.movimentacao.deposito_destino_id
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (
          this.movimentacao.op &&
          this.movimentacao.qtde &&
          this.movimentacao.deposito_id &&
          this.movimentacao.custo
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  methods: {
    insertCusto() {
      if (this.isAdmin) {
        let custo = {
          text: "Custo",
          value: "custo",
        };
        this.headers.splice(3, 0, custo);
      }
    },
    getProduto() {
      this.$Progress.start();
      this.loading = true;
      getOneProdutos(this.produtoId)
        .then((response) => {
          this.produto = response.produto;
          if (response.produto_custo[0]) {
            this.custoMedio = response.produto_custo[0].customedio;
          }
          this.original = this.lodash.cloneDeep(this.produto);
          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.produto.descricao
          ) {
            this.$store.commit("UPDATE_DYNAMICCRUMB", this.produto.descricao);
          }
          this.$Progress.finish();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
          this.loading = false;
        });
    },
    getProClass() {
      getAllProClass()
        .then((response) => {
          this.classificacoes = response;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingClass = false;
        });
    },
    getSelectDepositos() {
      this.$Progress.start();
      fetchDepositos()
        .then((response) => {
          this.selectDepositos = response;
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
    getDepositos() {
      this.$Progress.start();
      fetchDepositos(`?produto_id=${this.produtoId}`)
        .then((response) => {
          this.depositos = response;
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
    updateProduto() {
      if (this.lodash.isEqual(this.produto, this.original)) {
        this.$toast.error("Faça uma mudanca antes de atualizar");
      } else {
        this.$Progress.start();
        putProdutos(this.produtoId, this.produto)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Atualizado com sucesso!");
              this.original = this.lodash.cloneDeep(this.produto);
              this.$Progress.finish();
            }
          })
          .catch((error) => {
            console.log(error.response);
            this.$Progress.fail();
          });
      }
    },
    getMovimentacao(produtoId, depositoId) {
      this.$Progress.start();
      return new Promise((resolve, reject) => {
        getProdutoMovimentacoes(
          `?produto_id=${produtoId}&deposito_id=${depositoId}`
        )
          .then((response) => {
            response.forEach((item) => {
              item.editCusto = false;
            });
            this.movimentacoes = response;
            this.$Progress.finish();
            resolve();
          })
          .catch((error) => {
            console.log(error);
            this.$Progress.finish();
            reject();
          });
      });
    },
    updateMovimentacao(movimentacao) {
      let mov = this.lodash.cloneDeep(movimentacao);
      delete mov.serial;
      delete mov.cliente;
      delete mov.editCusto;
      delete mov.tipoevento;

      this.$Progress.start();
      putProdutoMovimentacoes(mov.id, mov)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Movimentação atualizada com sucesso!");
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    addMovimentacao() {
      this.movimentacao.produto_id = this.produtoId;
      if (this.transferencia) {
        if (this.custoMedio) {
          this.movimentacao.custo = this.custoMedio;
        }
        if (this.movimentacao.op) {
          delete this.movimentacao.op;
        }
        if (this.movimentacao.origem) {
          delete this.movimentacao.origem;
        }
      } else {
        if (this.movimentacao.deposito_destino_id) {
          delete this.movimentacao.deposito_destino_id;
        }

        if (this.movimentacao.op === "S") {
          if (this.custoMedio) {
            this.movimentacao.custo = this.custoMedio;
          }
          this.movimentacao.origem = "SAIDA MANUAL";
        } else {
          this.movimentacao.origem = "ENTRADA MANUAL";
        }
      }

      this.disableModal = true;
      this.$Progress.start();
      postProdutoMovimentacoes(this.movimentacao)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Movimentação adicionada com sucesso!");
            this.movimentacao = {};
            this.getDepositos();
            if (this.transferencia) {
              this.transferencia = false;
            }
            this.modalMovimentacao = false;
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          this.disableModal = false;
          this.$Progress.fail();
        });
    },
    openDepositoMovimentacoes(item) {
      if (this.vueProdutoMovimentacoes) {
        this.deposito = item;
        this.getMovimentacao(this.produtoId, item.id).then(() => {
          this.modalMovimentacoes = true;
        });
      }
    },
    updateAvatar(image) {
      this.produto.img = image;
      this.updateProduto();
      this.dialogImages = false;
    },
  },
  mounted() {
    if (this.vueProduto) {
      if (process.env.VUE_APP_API_URL) {
        this.apiUrl = process.env.VUE_APP_API_URL;
      }
      this.insertCusto();
      this.getProduto();
      this.getSelectDepositos();
      this.getDepositos();
      this.getProClass();
    }
  },
};
</script>

<style lang="scss" scoped>
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.btn-toggle-mobile {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  & > .v-btn.v-btn:not(:first-child) {
    border-left-width: thin;
  }
  & > .v-btn.v-btn:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  & > .v-btn.v-btn:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
